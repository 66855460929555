@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --font-family: 'Source Sans Pro';

  /*colors */
  --up-solid-black: #000000;
  --up-black-0: #ffffff;
  --up-black-5: #f5f5f5;
  --up-black-10: #eaeaea;
  --up-black-20: #d0d0d0;
  --up-black-30: #bababa;
  --up-black-40: #aaaaaa;
  --up-black-50: #8a8a8a;
  --up-black-60: #646464;
  --up-black-80: #363636;
  --up-black-100: #171717;

  --up-blue-5: #f2f5ff;
  --up-blue-10: #c5d1fc;
  --up-blue-20: #c6d2ff;
  --up-blue-30: #9fb3ff;
  --up-blue-35: #587af6;
  --up-blue-40: #3b5feb;
  --up-blue-50: #2543b6;
  --up-blue-40-filter: brightness(0) saturate(100%) invert(33%) sepia(98%)
    saturate(4438%) hue-rotate(225deg) brightness(96%) contrast(97%);

  --up-red-10: #ffe5e5;
  --up-red-50: #d64949;
  --up-red-70: #b02b2b;

  --up-yellow-10: #fef6e9;
  --up-yellow-35: #ffe55b;
  --up-yellow-40: #ffc045;
  --up-yellow-45: #e8c234;
  --up-yellow-50: #a8870f;
  --up-yellow-70: #846c18;

  --up-green-10: #ecf7f0;
  --up-green-30: #b6d53a;
  --up-green-40: #2ecc71;
  --up-green-50: #0da125;
  --up-green-70: #0b6e1b;

  --up-light-pink-50: #ffd1ff;

  --up-orange-30: #ffa05b;
  --up-orange-40: #fd9d52;

  --up-light-blue-50: #88d9f8;

  --up-teal-50: #20b2aa;

  --up-bright-pink-60: #ff709f;

  --up-purple-50: #9048c8;
  --up-purple-10: #f7edf7;

  --up-bright-red-50: #ff425c;

  /* box-shadows */
  --box-shadow-main-content: 0px 0px 12px rgba(0, 0, 0, 0.08);
  --box-shadow-side-drawer: -4px 0px 12px rgba(0, 0, 0, 0.08);
  --box-shadow-dark-dialog: 0px 0px 12px rgba(0, 0, 0, 0.16);

  --padding-4: 4px;
  --padding-8: 8px;
  --padding-12: 12px;
  --padding-16: 16px;
  --padding-24: 24px;
  --padding-32: 32px;
  --padding-40: 40px;
  --padding-48: 48px;
  --padding-56: 56px;
  --padding-64: 64px;

  --margin-4: 4px;
  --margin-8: 8px;
  --margin-12: 12px;
  --margin-16: 16px;
  --margin-24: 24px;
  --margin-32: 32px;
  --margin-40: 40px;
  --margin-48: 48px;
  --margin-56: 56px;
  --margin-64: 64px;
}

html,
button,
input,
select,
textarea {
  font-family: 'Source Sans Pro', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif;
  color: var(--up-black-80);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

img {
  max-width: 100%;
}

ol,
ul {
  margin: 0;
}
