.topNavbar {
  border-bottom: 1px solid var(--up-black-10);
}

.profilePopover:global.hrn-popover .hrn-popover-inner {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
}

.tooltip:global.hrn-tooltip .hrn-tooltip-inner {
  min-height: 10px;
  padding: 3px 8px;
  box-shadow: none;
}

.profilePopverContent {
  width: 350px;
}
.profilePopverContent > div {
  border-bottom: 1px solid var(--up-black-10);
}
.profilePopverContent > div:last-child {
  border-bottom: 0;
}